import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable()
export class LanguageService {
  mainStyleUlr = 'style.css';
  bootstrapStyleUrl = 'bootstrap.css';
  materialStyleUrl = 'material.css';
  private currentLang: string;
  constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService
  ) {
    this.currentLang = this.localStorageService.get('lang') as string;
  }

  // changeLang(lang){
  //   this.translateService.use(lang);
  //   this.localStorageService.set("lang", lang);
  //   this.currentLang = this.localStorageService.get("lang");
    
  //   if (this.currentLang === 'ar') {
  //     document.getElementById('html').setAttribute('lang', `ar`);
  //     document.getElementById('body_data').setAttribute('dir', `rtl`);
  //     document.getElementById('bootstrapStyle').setAttribute('href', `assets/arStyle/${this.bootstrapStyleUrl}`);
  //     document.getElementById('mainStyle').setAttribute('href', `assets/arStyle/${this.mainStyleUlr}`);
  //     document.getElementById('materialStyle').setAttribute('href', `assets/arStyle/${this.materialStyleUrl}`);
  //   } else if (this.currentLang === 'en') {
  //     document.getElementById('html').setAttribute('lang', `en`);
  //     document.getElementById('body_data').setAttribute('dir', `ltr`);
  //     document.getElementById('bootstrapStyle').setAttribute('href', `assets/enStyle/${this.bootstrapStyleUrl}`);
  //     document.getElementById('mainStyle').setAttribute('href', `assets/enStyle/${this.mainStyleUlr}`);
  //     document.getElementById('materialStyle').setAttribute('href', `assets/enStyle/${this.materialStyleUrl}`);
  //   }
  // }

  getCurrentLang(){
    if (this.currentLang) {
      return this.currentLang
    } else {
      return environment.defaultLanguage;
    }
  }

  getLanguageOrDefault(){
    if (this.currentLang) {
      return this.currentLang
    } else {
      return environment.defaultLanguage;
    }
  }
}
