// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultDateFormat: 'DD-MM-YYYY',
  defaultPageSize: 20,
  baseUrl: "https://konarabia.com",
  api_imges: 'https://backend.konarabia.com',
  api_base_url: 'https://backend.konarabia.com/api',
  api_url: 'https://backend.konarabia.com/api',
  // baseUrl: "http://localhost:4300",
  // api_imges: 'http://127.0.0.1:8000',
  // api_base_url: 'http://127.0.0.1:8000',
  // api_url: 'http://127.0.0.1:8000/api',
  defaultLanguage: 'en',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
