import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap} from 'rxjs/operators';

import { LocalStorageService } from 'angular-2-local-storage';
import { LanguageService } from './language.service';

export interface ResultViewModel {
    Success: boolean;
    Message: string;
    Data: any;
    IsAuthorized: boolean;
}

@Injectable()
export class WebApiService {
    resources: any[] = [];
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private languageService: LanguageService,

    ) {}

    private dataSource = new BehaviorSubject<any>(null);
    sharedData = this.dataSource.asObservable();

    updateSharedData(newData: {}){
        this.dataSource.next(newData);
    }
    private setHeaders(): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/json',
            'X-localization': this.languageService.getCurrentLang(),
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'X-Requested-With': 'application/XMLHttpRequest',
            Authorization: String('Bearer ' + this.localStorageService.get('accessToken') || ''),
        };
        return new HttpHeaders(headersConfig);
    }
    private logError(filename, error: any) {

    }
    private log(path, data) {
        console.log("data,data",data)
        if (!environment.production) {}
    }

    get(path: string, params?: HttpParams): Observable<ResultViewModel> {
        return this.http.get<ResultViewModel>(`${environment.api_url}${path}`,
        { headers: this.setHeaders(), params }).pipe(tap(data => this.log(path, data),
        error => {if(error.status == 401 || error.status == 403) {
            this.localStorageService.clearAll()
        }  
        return this.logError(path, error)}));
    }
    post(path: string, body: Object = {}): Observable<ResultViewModel> {
        return this.http.post<ResultViewModel>(`${environment.api_url}${path}`,
        body, { headers: this.setHeaders() }).pipe(tap(data => this.log(path, data),
        error => this.logError(path, error)));
    }
    put(path: string, body: Object = {}): Observable<ResultViewModel> {
        return this.http.put<ResultViewModel>
        (`${environment.api_url}${path}`, body,
        { headers: this.setHeaders() }).pipe
        (tap(data => this.log(path, data),
         error => this.logError(path, error)));
    }
    delete(path: string, params?: HttpParams): Observable<ResultViewModel> {
        return this.http.delete<ResultViewModel>(`${environment.api_url}${path}`, { headers: this.setHeaders(), params }).pipe(tap(data => this.log(path, data), error => this.logError(path, error)));
    }
    fileUpload(path: string, body: Object = {}): Observable<ResultViewModel> {
        return this.http.post<ResultViewModel>(`${environment.api_url}${path}`, body, { headers: this.setHeaders(), reportProgress: true }).pipe(tap(data => this.log(path, data), error => this.logError(path, error)));
    }
    publicPost(path: string, body: Object = {}): Observable<ResultViewModel> {
        return this.http.post<ResultViewModel>(`${environment.api_url}${path}`, body, { headers: this.setHeaders() }).pipe(tap(data => this.log(path, data), error => this.logError(path, error)));
    }
    publicGet(path: string, params?: HttpParams): Observable<ResultViewModel> {
        return this.http.get<ResultViewModel>(`${environment.api_url}${path}`, { headers: this.setHeaders(), params }).pipe(tap(data => this.log(path, data), error => this.logError(path, error)));
    }
}
